
/* Comman Css Start */
.btn {
  font-weight: 700;
  padding: 0.75rem 2.5rem; 
  cursor: pointer;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
  font-size: 16px;
  line-height: 21px; 
  border-radius: 8px;
}
.btn:focus{
    box-shadow: var(--inputShadowFocus);
}

.btn:focus-visible {
    outline: -webkit-focus-ring-color auto 1px;
    outline-offset: 5px;
 
}

*:focus-visible {
    outline: -webkit-focus-ring-color auto 1px;
    outline-offset: 1px;
  }

.btn-teal {
    background-color: var(--themeButton);
    color: var(--themeButtonColor);
    box-shadow: 0 12px 24px -4px rgb(46 91 115 / 30%), 0 6px 8px -3px rgb(0 0 0 / 5%);
}

.btn-teal:hover,
.btn-teal:focus {
    background-color: var(--themeButtonhover);
    color: var(--themeButtonColorhover);
    box-shadow: none;
}
.btn-white {
    background-color: #fff;
    color: var(--themeButton);
    box-shadow: 0 12px 24px -4px rgb(46 91 115 / 30%), 0 6px 8px -3px rgb(0 0 0 / 5%);
}

.btn-white:hover,
.btn-white:focus {
    background-color: #fff;
    color: var(--themeButton);
    box-shadow: none;
}

.btn-outline {
    border: 2px solid var(--themeButton);  
    background-color: #fff;
    color: var(--themeButton);  
}

.btn-outline:hover,
.btn-outline:focus { 
    background-color: rgba(var(--themeColorRgba), 0.1); 
    border-color:  rgba(var(--themeColorRgba), 1); ;
    box-shadow: none;
}

.btn:disabled {
    --bg-opacity: 1;
    background-color: #EBEEF0; 
    --text-opacity: 1;
    color: #666; 
    box-shadow: none;
    border-style: none;
    pointer-events: none;
}

a.text-fs-16 {
    color: var(--theme);
    font-weight: bold;
}

.text-teal {
    color: var(--theme);
}
.mx-6 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
}
.font-bold {
    font-weight: 700;
}
.border-b {
    border-bottom: 1px solid #000;
}

.border-t {
    border-top: 1px solid #000;
}
.h-full {
    height: 100%;
}
 
button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
}
.mt-12 {
    margin-top: 3rem;
}
.bg-yellow{
    background-color: #F2BA16;
}
.bg-beige-light { 
    background-color: #FAF5EB; 
}
.text-charcoal { 
    color: #151E1F; 
}

.svgIcon{
    margin-left: 0.5rem;
    width: 1.5rem;
}
.mt-8 {
    margin-top: 2rem;
}
.mr-8 {
    margin-right: 2rem;
}
.mr-6 {
    margin-right: 1.5rem;
}
.pb-16 {
    padding-bottom: 4rem;
} 
.mt-24 {
    margin-top: 6rem;
}
.w-60{
    width: 60%;
}
.w-90{
    width: 90%;
}
.min-h-full {
    min-height: 100%;
}

.mt-auto{
    margin-top: auto;
}

.transform {
    --transform-translate-x: 0;
    --transform-translate-y: 0;
    --transform-rotate: 0;
    --transform-skew-x: 0;
    --transform-skew-y: 0;
    --transform-scale-x: 1;
    --transform-scale-y: 1;
    transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
}
.translate-x-full {
    transform: translateX(100%);
}
.animate-tooltip {
    -webkit-animation: fadeIn 1250ms cubic-bezier(0.27, 0, 0.23, 1) normal;
    animation: fadeIn 1250ms cubic-bezier(0.27, 0, 0.23, 1) normal;
}
.animate-tooltipIcon {
    -webkit-animation: scaleIn 750ms cubic-bezier(0.27, 0, 0.23, 1) normal;
            animation: scaleIn 750ms cubic-bezier(0.27, 0, 0.23, 1) normal;
  }  
.inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .opacity-50 {
     opacity: 0.5; 
}

.text-fs-68 {
    font-size: 68px;
    line-height: 82px;
}

.text-fs-48 {
    font-size: 48px;
    line-height: 58px;
}

.text-fs-40 {
    font-size: 40px;
    line-height: 48px;
}

.text-fs-32 {
    font-size: 32px;
    line-height: 38px;
}

.text-fs-26 {
    font-size: 26px;
    line-height: 30px;
}

.text-fs-24 {
    font-size: 24px;
    line-height: 28px;
}

.text-fs-20 {
    font-size: 20px;
    line-height: 24px;
}

.text-fs-18 {
    font-size: 18px;
    line-height: 22px;
}

.text-fs-16 {
    font-size: 16px;
    line-height: 21px;
}

.text-fs-14 {
    font-size: 14px;
    line-height: 16px;
}

.text-fs-12 {
    font-size: 12px;
    line-height: 14px;
}
.text-gray-04 { 
    color: #656B70; 
}

.fixed {
    position: fixed;
  }
  
  .absolute {
    position: absolute;
  }
  
  .relative {
    position: relative;
  }
  
  .sticky {
    position: -webkit-sticky;
    position: sticky;
  }
.top-0 {
    top: 0;
  }
  
  .right-0 {
    right: 0;
  }
  .right-15 {
    right: 15px;
  }
  .bottom-0 {
    bottom: 0;
  }
  
  .left-0 {
    left: 0;
  }
  
  .top-6 {
    top: 1.5rem;
  }
  .z-30{
      z-index: 3;
  }
  .px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
}

.hidden {
  display: none;
}


span,
p {
  font-family: var(--themeFont);
  font-weight: 300;
}
button.link {
    background-color: transparent;
    border: 0;
    cursor: pointer;
}
a ,
button.link {
  font-size: 15px;
  font-family: var(--themeFont);
  font-weight: 400;
  color: var(--themeColor);
  transition: 0.5s;
}

a svg{
    transition: 0.5s;
}

a:hover,
a:focus,
button.link:hover,
button.link:focus {
  color: var(--themeColor);
  text-decoration: inherit;
}

a.btn,
a.btn {
  font-weight: 700;
  text-decoration: inherit;
}

.link {
    color: var(--themeColor);
}

.link:hover,
.link:focus{
    color: var(--linkHoverColor);
}

ul {
    margin: 0px;
    padding: 0px;
  }
  

.legend-btn{
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 999;
}
.btn.btn-light-gray.mb-4.green_bg {
    background-color: var(--themeButton);
    color: var(--themeButtonColor);
}

.btn-yellow {
    background-color: #F2BA16;
    border-style: solid;
    border-width: 2px;
    border-color: #F2BA16;
    color: #ffffff;
}

/* Comman Css End */

/* Responsive Css Start */

@media (max-width: 1600px){
.xl\:text-fs-12 {
    font-size: 12px;
    line-height: 14px;
}
}
/* Responsive Css End */
