@font-face {
    font-family: 'Circular Std';
    src:  url('../fonts/CircularStd-Medium.woff2') format('woff2'),
          url('../fonts/CircularStd-Medium.woff') format('woff');
    font-weight: 500;
  }
  
  @font-face {
    font-family: 'Circular Std';
    src:  url('../fonts/CircularStd-Book.woff2') format('woff2'),
          url('../fonts/CircularStd-Book.woff') format('woff');
    font-weight: 300;
  }
  
  @font-face {
    font-family: 'Circular Std';
    src:  url('../fonts/CircularStd-Bold.woff2') format('woff2'),
          url('../fonts/CircularStd-Bold.woff') format('woff');
    font-weight: 700;
  }
  
  @font-face {
    font-family: 'Circular Std';
    src:  url('../fonts/CircularStd-Black.woff2') format('woff2'),
          url('../fonts/CircularStd-Black.woff') format('woff');
    font-weight: 900;
  }
  @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,700;1,100&display=swap');